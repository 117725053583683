import axios from "axios"
import { graphql } from "gatsby"
import React, { useReducer, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import qs from "qs"

const formReducer = (state, event) => {
  if (event.reset) {
    return {
      comment: "",
      name: "",
      email: "",
    }
  }

  return {
    ...state,
    [event.name]: event.value,
  }
}

const Article = ({ data }) => {
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useReducer(formReducer, {
    comment: "",
    name: "",
    email: "",
    reset: false
  })

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  const postComment = async event => {
    event.preventDefault()

    const data = qs.stringify({
      "form-name": "comment-form",
      comment: formData.comment,
      name: formData.name,
      email: formData.email,
    })

    await axios.post(window.location.pathname, data)

    setSubmitted(true)
    setFormData({
      reset: true,
    })
  }

  const handleChange = event => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    })
  }

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
      </Helmet>
      <article>
        <header>
          <h1>{frontmatter.title}</h1>
          <p className="text-gray-600">By Steve Carrupt</p>
          <p className="text-gray-600 italic">
            Published or Updated on {frontmatter.date}
          </p>
        </header>
        <section
          className="markdown"
          dangerouslySetInnerHTML={{ __html: html }}
        ></section>
      </article>
      <section className="my-5">
        <h1>Leave a comment</h1>
        <p className="text-gray-600 italic">
          Your comment may not instantly appear since it has to go through
          moderation. Also, your email address will not be published.
        </p>
        {submitted && (
          <div
            className="flex items-center bg-blue-400 text-white text-sm font-bold mt-2 px-4 py-3"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p>Your comment has been submitted. Thank you!</p>
          </div>
        )}
        <form
          autoComplete="false"
          method="post"
          name="comment-form"
          onSubmit={postComment}
          netlify-honeypot="bot-field"
          data-netlify="true"
          className="mt-5"
        >
          <input type="hidden" name="form-name" value="comment-form" />
          <div className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-2">
              <label htmlFor="comment">Comment</label>
              <textarea
                id="comment"
                name="comment"
                className="shadow-sm border border-gray-300 rounded-md px-2 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                value={formData.comment}
                rows={10}
                required
                maxLength={5000}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                name="name"
                className="shadow-sm border border-gray-300 rounded-md leading-8 px-2 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                value={formData.name}
                required
                maxLength={200}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                className="shadow-sm border border-gray-300 rounded-md leading-8 px-2 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                value={formData.email}
                required
                maxLength={254}
              />
            </div>
            <button
              type="submit"
              className="text-white bg-blue-400 border-0 py-2 px-6 focus:outline-none hover:bg-blue-500 rounded text-lg"
            >
              Post
            </button>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export const getArticle = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
      }
      html
    }
  }
`

export default Article
